<template>
  <div>
    <b-row class="mt-2">
      <div class="d-block" :style="cardStyle">
        <b-card class="mb-1 passport-card" body-class="p-0">
          <safe-img 
            :src="banner" 
            :placeholder="placeholder" 
            :style="headerBg" 
          />

          <h1 class="text-center mt-3">
            {{ loggedMember.name }} {{ loggedMember.surname }}
          </h1>
          <h3 class="text-center font-weight-normal">
            {{ loggedMember.headline }}
          </h3>
          <!-- Organization -->
          <h3 class="text-center">
            <span v-for="(organization, index) in organizations" :key="index" class="d-flex justify-content-center align-items-center mb-75">
              <b-img v-if="organization.logoURL" :src="organization.logoURL" class="mr-50" style="max-width: 30px"/>
              <p class="mb-0">
                {{ organizationName(organization) }}
              </p>
            </span>
          </h3>
          <!-- QR -->
          <img 
            :src="link" 
            style="width: 250px" class="d-block mx-auto small-card mb-3 mt-2">
          <!-- ID number -->
          <h6 class="text-center font-weight-normal">
            {{ hexToDecimal(loggedMember) }}
          </h6>
          <!-- Footer -->
          <b-card-footer class="bg-light" footer-border-variant="white">
            <b-img 
              v-if="currentCollective.logoURL" 
              :src="currentCollective.logoURL" 
              class="mr-50 d-block mx-auto mb-50" 
              style="max-width: 30px"
            />
            <h5 class="text-center mb-0">
              {{ currentCollectiveName }}
            </h5>
            <p class="text-center mb-0">
              <small>
                {{ collectiveHeadline }}
              </small>
            </p>
          </b-card-footer>
        </b-card>

        <!-- Actions -->
        <div v-if="isModal">
          <div class="d-flex flex-wrap justify-content-center mb-2">
            <b-button class="font-weight-bold mt-1 mr-50" :variant="'primary'" @click="handlePrintCode()">
              <span class="d-flex pb-25">
              <feather-icon icon="PrinterIcon" size="18" class="d-block mx-auto mr-1" />
                <span class="pl-50 pt-25">
                  {{ $t('settings.print-code') }}
                </span>
            </span>
            </b-button>
            <b-button class="font-weight-bold mt-1 mr-50" :variant="'primary'" @click="handleClipboardCopied()">
              <span class="d-flex pb-25">
                <feather-icon icon="CopyIcon" size="18" class="d-block mx-auto mr-1" />
                <span class="pl-50 pt-25">
                  {{ $t('share.clipboard-title') }}
                </span>
              </span>
            </b-button>
          </div>
          <b-alert :show="isCopied" variant="success" class="text-center">
            {{ $t('share.copied-message') }}
          </b-alert>
        </div>
      </div>

      <!-- Printing Contents -->
      <div id="printContents" :class="this.isPrinting ? 'd-block' : 'd-none'">
        <div class=" d-block passport mx-auto bg-white mt-4">
          <safe-img 
            :src="currentCollective.bgURL" 
            :placeholder="placeholder" 
            :style="headerBg" />
          <h1 class="text-center mt-4">
            {{ loggedMember.name }} {{ loggedMember.surname }}
          </h1>
          <h3 class="text-center font-weight-normal">
            {{ loggedMember.headline }}
          </h3>
          <!-- Organization -->
          <h3 class="text-center">
            <span v-for="(organization, index) in organizations" :key="index" class="d-flex justify-content-center align-items-center">
              <b-img v-if="organization.logoURL" :src="organization.logoURL" class="mr-50" style="max-width: 30px"/>
              <p class="mb-0">{{ organizationName(organization) }}</p>
            </span>
          </h3>
          <!-- QR -->
          <img :src="link" style="max-width: 300px; height: auto;" class="d-block mx-auto small-card my-2">
          <!-- Footer -->
          <div class="bg-light mt-2 pb-2" footer-border-variant="white" style="border-radius: 1.2rem">
            <b-img v-if="currentCollective.logoURL" :src="currentCollective.logoURL" class="mr-50 d-block mx-auto pt-1 pb-2 mt-1" style="max-width: 30px"/>
            <h5 class="text-center mb-0">{{ currentCollectiveName }}</h5>
            <p class="text-center mb-0"><small>{{ collectiveHeadline }}</small></p>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import QRCode from 'qrcode';

export default {
  name: 'PassportBO',
  components: {
    SafeImg,
  },
  props: {
    loggedMember: {
      type: Object,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    copied: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isStaff: false,
      isModalOpened: false,
      isCopied: false,
      isModalAttendanceOpened: false,
      isSmall: false,
      isPrinting: false,
      link: ''
    };
  },
  computed: {
    collectiveHeadline() {
      if (typeof this.currentCollective.headline === 'string') {
        return this.currentCollective.headline;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective.headline);
    },
    currentCollectiveName() {
      if (typeof this.currentCollective.name === 'string') {
        return this.currentCollective.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective.name);
    },
    placeholder() {
      return HeaderPlaceholder;
    },
    organizations() {
      return this.loggedMember.organizations;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    headerBg() {
      return {
        'border-radius': '1.2rem 1.2rem 0 0',
        'height': '150px',
        'width': '500px',
        'object-fit': 'cover',
      };
    },
    cardStyle() {
      return {
        'width': '100%',
      };
    },
    banner() {
      return this.currentCollective?.bgURL;
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.currentCollective.subdomain || 'app',
      ).replace('{slug}', this.currentCollective.slug);
      return `${url}/backoffice/members/${this.loggedMember.username}`;
    },
    // link() {
    //   console.log('this.qrUrl', this.qrUrl);

    //   const qrCHL = encodeURIComponent(this.qrUrl);
    //   console.log('qrCHL', qrCHL);

    //   const qrLink = `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrCHL}&choe=UTF-8`;
    //   console.log('qrLink', qrLink);

    //   return qrLink;
    // },
  },
  watch: {
    printing(value) {
      if (value === true) {
        this.handlePrintCode();
      }
    },
    copied(value) {
      if (value === true) {
        this.handleClipboardCopied();
      }
    },
  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
    window.addEventListener('resize', this.handleResize);
    //this.handleResize();

    // Generate QR:
    this.link = await this.generateQR(this.qrUrl);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    organizationName(organization) {
      if (typeof organization.name === 'string') {
        return organization.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, organization.name);
    },
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 600;
    },
    handlePrintCode() {
      document.body.innerHTML = document.getElementById("printContents").innerHTML;
      this.isPrinting = true;
      window.print();
      window.onafterprint = this.afterPrint();
    },
    afterPrint() {
      this.isPrinting = false;
      window.location.reload();
    },
    hexToDecimal(member) {
      return parseInt(member?.key, 16);
    },
    async generateQR(text) {
      try {
        const qrLink = await QRCode.toDataURL(text);
        console.log('qrLink', qrLink)
        return qrLink;
      } catch (err) {
        console.error(err)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
  .passport {
    border: 1px solid #bfbfbf;
    border-radius: 1.2rem;
    width: 500px;

    &-card {
      max-width: 500px; 
      margin: auto;
    }
  }
</style>